import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ConfigManagerModule, CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';
import { DataApiModule } from '@xpo-ltl/data-api';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { XpoLtlAuthUiModule } from '@xpo-ltl/ngx-auth-ui';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';

import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { XpoFooterModule } from '@xpo-ltl/ngx-ltl-core/footer';
import { XpoFormFieldModule } from '@xpo-ltl/ngx-ltl-core/form-field';
import { XpoInlineSearchModule } from '@xpo-ltl/ngx-ltl-core/inline-search';
import { XpoShellModule } from '@xpo-ltl/ngx-ltl-core/shell';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { SdkFirebaseModule } from '@xpo-ltl/sdk-firebase';
import { XpoAuthConfig, XPO_AUTH_CONFIG } from '@xpo/ngx-auth';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SicComponent } from './sic/sic.component';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}
@NgModule({
  declarations: [AppComponent, SicComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserAnimationsModule,
    BrowserModule,
    ConfigManagerModule,
    DataApiModule,
    XpoFooterModule,
    XpoLtlAuthUiModule,
    XpoShellModule,
    XpoButtonModule,
    XpoSnackBarModule,
    XpoInlineSearchModule,
    XpoFormFieldModule,
    SdkFirebaseModule,
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    AppRoutingModule,
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
