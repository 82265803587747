<xpo-shell [drawerClosedByDefault]="true">
  <xpo-header-app-name>Edge</xpo-header-app-name>
  <xpo-header-sub-app-name>PDCS Authorized Users</xpo-header-sub-app-name>
  <xpo-header-actions>
    <xpo-auth-user-profile></xpo-auth-user-profile>
  </xpo-header-actions>
  <div class="content">
    <router-outlet></router-outlet>
    <xpo-footer xpoFooterSticky [version]="build"></xpo-footer>
  </div>
</xpo-shell>
