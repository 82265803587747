<div>
  <form class="sic-form" xpoFormInline>
    <mat-form-field floatLabel="always">
      <mat-label>SIC : </mat-label>
      <input #sicD matInput placeholder="Enter SIC" [value]="sicCode" required maxlength="3" type="string" />
    </mat-form-field>
    <button mat-flat-button xpoSmallButton xpoButton (click)="download(sicD.value)">Download</button>
  </form>
  <div style="white-space:pre-wrap"></div>
<div>
<form class="sic-form" xpoFormInline>
  <div style="white-space:pre-wrap"></div>
  <div *ngIf="env; else elseBlock">
    <div style="text-decoration: underline;">   
      <a href="https://pdcs.ltl-xpo.com/" target="_blank"><strong>EDGE PDCS</strong></a>
    </div>
    <div style="white-space:pre-wrap"></div>
  </div>
  <ng-template #elseBlock>
    <div style="text-decoration: underline;">   
      <a href="https://pdcs.dev-ltl-xpo.com/" target="_blank"><strong>EDGE PDCS</strong></a>
    </div>
    <div style="white-space:pre-wrap"></div>
  </ng-template>
  <div style="white-space:pre-wrap"></div> 
  </form>
</div>
  <div>
  <form class="sic-form" xpoFormInline>
    <div style="white-space:pre-wrap"></div>
  <div style="text-decoration: underline;">
    <a href="https://xpologistics.sharepoint.com/sites/150/ttdd/BRP_Cert/SitePages/Home.aspx" target="_blank"><strong>Visit L&D SharePoint for BRP</strong></a>
  </div>
</form>
</div>
