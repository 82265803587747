import { Component, Inject, Input, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { FirebaseOptions, FIREBASE_OPTIONS } from '@angular/fire';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormControl, Validators } from '@angular/forms';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { FirebaseApiService } from '@xpo-ltl/sdk-firebase';
import { ConfigManagerProperties } from '../shared/enums/config-manager-properties.enum';

@Component({
  selector: 'app-sic',
  templateUrl: './sic.component.html',
  styleUrls: ['./sic.component.scss'],
})
export class SicComponent implements OnInit {
  env: boolean = false;
  constructor(
    @Inject(FIREBASE_OPTIONS) private firebaseOptions: FirebaseOptions,
    private ngZone: NgZone,
    private configManagerService: ConfigManagerService,
    private snackBar: XpoSnackBar,
    private firebaseApiService: FirebaseApiService
    
  ) {
    const isProd: boolean = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);
    const project: string = isProd ? 'xpo-ltl-prd' : 'xpo-ltl-dev';
    const region: string = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.storageBucket = new AngularFireStorage(
      this.firebaseOptions,
      undefined,
      `${project}-${region.toLowerCase()}-pdcs-bch-weeklypwdpdf`,
      PLATFORM_ID,
      this.ngZone,
      null,
      null
    );
  }
  sicCode: string = '';
  private storageBucket: AngularFireStorage;

  ngOnInit(): void {
    this.firebaseApiService.loggedInUser().subscribe((user) => {
      this.sicCode = user.sicCode;
    });
    this.env = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);

  }

  download(sicCode: string): void {
    if (sicCode && sicCode.length === 3) {
      this.storageBucket
        .ref(sicCode.toUpperCase())
        .getDownloadURL()
        .subscribe(
          (url) => window.open(url, '_blank'),
          (error) => this.snackBar.error(`BRP File is not available for SIC: ${sicCode.toUpperCase()}`)
        );
    } else {
      this.snackBar.warn('Please Enter a Valid SIC.');
    }
  }
}
