import { Component, OnInit } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';

@Component({
  selector: 'xpo-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  build: string = '';

  constructor(private configManagerService: ConfigManagerService) {}

  ngOnInit(): void {
    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    const prod = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);
    if (prod) {
      this.build = this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
    } else {
      this.build = `${this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion)} - ${region}`;
    }
  }
}
