import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoLtlAuthGuard } from '@xpo-ltl/ngx-auth';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { SicComponent } from './sic/sic.component';

const routes: Routes = [
  {
    path: 'sic',
    component: SicComponent,
    canActivate: [XpoAuthenticationGuard, XpoLtlAuthGuard],
  },
  {
    path: '',
    redirectTo: 'sic',
    pathMatch: 'full',
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
